<script setup>
import {computed, onMounted, ref} from 'vue';
import {usePage} from "@inertiajs/inertia-vue3";

defineProps({
    modelValue: String,
    disabled: Boolean,
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
const page = usePage();

const ringColor = computed(()=> 'ring-['+page.props.value.primiaryColor+']');

const doSomething = (e) => {

	e.target.style.borderColor = page.props.value.primaryColor;
	e.target.style.borderWidth = '1px';
	e.target.style.borderOpacity = 50;
};
const doSomethingElse = (e) => {
	e.target.style.borderColor = '#d1d5db'; //50 percent
	e.target.style.borderWidth = '1px';
	e.target.style.borderOpacity = 30;
};
</script>

<template>
    <input
		    @focusin="doSomething" @focusout="doSomethingElse"
        :disabled="disabled"
        ref="input"
        :class="[disabled ? 'bg-gray-200 text-gray-500' : 'bg-primaryWhite text-black']"
        class="border border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-full shadow-sm"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>
